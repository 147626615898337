<template>
    <div class="bid_wrap license_wrap vote_detail item_status_wrap">
        <div class="bid_main_wrap">
            <div class="bid_content_wrap">
                <div class="bid_title_wrap">
                    <h1 class="title">{{ $t('i_status') }}</h1>
                </div>

                <div class="content_wrap">
                    <div class="img_wrap">
                        <div class="b_img">
                            <img :src="returnItemImg(itemData)"/>
                        </div>
                    </div>
                    <div class="inform_wrap">
                        <div class="inform_header">
                            <div class="i_header_top">
                                <div class="trash_btn" @click="deleteItem"
                                     v-if="itemData.im_status !== 1 && itemData.provider === UserInfo.mb_no"></div>
                            </div>
                        </div>
                        <div class="inform_content">
                            <div class="i_content_wrap">
                                <ul>
                                    <li>
                                        <div>{{ $t('type') }}</div>
                                        <div><span>{{ returnItemType(itemData.i_type) }}</span></div>
                                    </li>
                                    <li>
                                        <div>{{ $t('category') }}</div>
                                        <div><span>{{ returnCategoryName(itemData.ic_idx) }}</span></div>
                                    </li>
                                    <li>
                                        <div>{{ $t('i_name') }}</div>
                                        <div><span>{{ itemData.i_name }}</span></div>
                                    </li>
                                    <li class="edition_wrap" v-if="itemData.im_edition === 1">
                                        <div>{{ $t('edition') }}</div>
                                        <div v-if="itemData.i_type === 'real'">
                                            <span>{{ itemData.im_count }} of {{ itemData.im_total }}</span></div>
                                        <div v-else><span> {{ itemData.im_count }} of {{ itemData.im_total }} </span>
                                        </div>
                                    </li>
                                    <li class="file_wrap">
                                        <div>{{ $t('certi') }}</div>
                                        <div style="width: 243px; height: 36px;"><span v-for="(data,index) in guarantee"
                                                                                       :key="`guranty${index}`"
                                                                                       @click="awsS3Download('guaranty',data,itemData.i_idx)">
                                            {{ data.org_name }}</span></div>
                                    </li>
                                    <li class="file_wrap" v-if="original.length > 0">
                                        <div>{{ $t('f_real') }}</div>
                                        <!--                                        <div style="width: 243px; height: 36px;"><span @click="fileDownload()">{{-->
                                        <!--                                                original[0].org_name-->
                                        <!--                                            }}</span></div>-->
                                        <!--                                        <div style="width: 243px; height: 36px;"><span-->
                                        <!--                                                @click="getFileJwtToken('own', itemData.i_idx)">{{-->
                                        <!--                                                original[0].org_name-->
                                        <!--                                            }}</span></div>-->
                                        <div style="width: 243px; height: 36px;"><span
                                                @click="awsS3Download('original',original[0],itemData.i_idx,true)">{{
                                                original[0].a_filename
                                            }}</span></div>

                                    </li>
                                    <li class="creator_wrap" v-if="checkOriginal()">
                                        <div>{{ $t('k_creator') }}</div>
                                        <div>
                                            <img :src="returnProfile(itemData)"/>
                                            <span>{{ itemData.originator_nick }}</span></div>
                                    </li>
                                    <li class="detail_info_box">
                                        <div class="detail_title">{{ $t('detail_info') }}</div>
                                        <div class="detail_wrap" v-if="itemData.im_type==='photo'">
                                            <div>
                                                <span>{{ $t('t_video2') }}</span><span> {{ itemData.imo_date }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_country2') }}</span>
                                                <span> {{ itemData.imo_country }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_si2') }}</span><span> {{ itemData.imo_si }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_city2') }}</span><span> {{ itemData.imo_city }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('size2') }}</span><span> {{ itemData.imo_size }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('copyright_info2') }}</span>
                                                <span> {{ itemData.imo_copyright }}</span>
                                            </div>
                                        </div>
                                        <div class="detail_wrap" v-else-if="itemData.im_type==='video'">
                                            <div>
                                                <span>{{ $t('t_video2') }}</span><span> {{ itemData.imo_date }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_country2') }}</span>
                                                <span> {{ itemData.imo_country }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_si2') }}</span><span> {{ itemData.imo_si }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_city2') }}</span><span> {{ itemData.imo_city }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_long') }} : </span><span> {{ itemData.imo_size }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('copyright_info2') }}</span>
                                                <span> {{ itemData.imo_copyright }}</span>
                                            </div>
                                        </div>
                                        <div class="detail_wrap" v-else-if="itemData.im_type==='audio'">
                                            <div>
                                                <span>{{ $t('t_record') }} : </span><span> {{
                                                    itemData.imo_date
                                                }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('c_country') }} : </span>
                                                <span> {{ itemData.imo_country }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('c_si') }} : </span><span> {{ itemData.imo_si }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('c_city') }} : </span><span> {{ itemData.imo_city }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('v_long') }} : </span><span> {{ itemData.imo_size }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('copyright_info2') }}</span>
                                                <span> {{ itemData.imo_copyright }}</span>
                                            </div>
                                        </div>
                                        <div class="detail_wrap" v-else>
                                            <div>
                                                <span>{{ $t('t_video2') }}</span><span> {{ itemData.imo_date }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('c_country') }} : </span>
                                                <span> {{ itemData.imo_country }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('c_si') }} : </span><span> {{ itemData.imo_si }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('c_city') }} : </span><span> {{ itemData.imo_city }}</span>
                                            </div>
                                            <div>
                                                <span>{{ $t('copyright_info2') }}</span>
                                                <span> {{ itemData.imo_copyright }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="status_content_wrap">
                    <div class="status_content_box">
                        <h2>{{ $t('info_item') }}</h2>
                        <ul class="i_write_content_wrap">

                            <!--              상세페이지 썸네일-->
                            <li class="i_w_content detail_thumb_wrap ">
                                <h3 class="sub_title no_caution_img">{{ $t('d_thumb') }}</h3>
                                <div class="w_content_box" @click="changeFileType('detailThumnail')">
                                    <!--                                    <el-upload
                                                                                v-for="(data,index) in detailThumnail"
                                                                                :key="`detailThum${index}`"
                                                                                class="c_el_up"
                                                                                action=""
                                                                                list-type="picture-card"
                                                                                :show-file-list="false"
                                                                                :file-list="thumnail"
                                                                                :auto-upload="false"
                                                                                :on-change="photoListChange"
                                                                        >
                                                                            <img v-if="hasFile('detailThumnail')"
                                                                                 :src="data.org_url" class="">
                                                                            <button v-if="hasFile('detailThumnail')" class="upload_del_btn"
                                                                                    @click="removeList('detailThumnail',0)"></button>
                                                                        </el-upload>-->
                                    <div class="c_el_up" v-for="(data,index) in detailThumnail"
                                         :key="`detailThum${index}`">
                                        <div class="el-upload el-upload--picture-card">
                                            <img v-if="hasFile('detailThumnail')"
                                                 :src="data.org_url" class="">
                                            <button v-if="hasFile('detailThumnail')" class="upload_del_btn"
                                                    @click="removeList('detailThumnail',index)"></button>
                                        </div>
                                    </div>
                                    <el-upload
                                            class="n_el_up"
                                            action=""
                                            list-type="picture-card"
                                            :show-file-list="false"
                                            :file-list="detailThumnail"
                                            :auto-upload="false"
                                            :on-change="photoListChange"
                                    >
                                        <img src="@/assets/image/main/change_item.png" alt=""
                                             style="width: 93px; height: 74px;">
                                    </el-upload>
                                </div>
                            </li>

                            <!--              성인인증 필요-->
                            <li class="i_w_content adult_wrap">
                                <h3 class="sub_title no_caution_img ">{{ $t('adult_in') }}</h3>
                                <div class="w_content_box">
                                    <el-radio-group class="el_radio_box" v-model="adult"
                                                    @change="parentChange('adult', adult)">
                                        <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                                        <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                                    </el-radio-group>
                                </div>
                            </li>

                            <!--              해시태그-->
                            <li class="i_w_content hash_wrap">
                                <h3 class="sub_title no_caution_img">{{ $t('hashtag') }}</h3>
                                <div class="w_content_box hash_tag">
                                    <div class="content_name ">
                                        {{ $t('d_category') }}
                                    </div>
                                    <div class="w_content">
                                        <el-select id="hash" v-model="hashData" class="select"
                                                   :placeholder="$t('s_category')"
                                                   popper-class="category_select"
                                                   :popper-append-to-body="false"
                                                   @change="changeHash(1,hashData)">
                                            <el-option :value="data.node_id" v-for="data in detailCategorys.child"
                                                       :key="`item${data.node_id}`" :label="data.NAME">
                                            </el-option>
                                        </el-select>
                                        <ul class="hash_tag_wrap">
                                            <li v-for="(data,index) in hashtag" :key="`hash${index}`"
                                                v-if="data.type === 1">
                                                <div class="hash_wrap">
                                                    <span class="hash_name">{{ returnHashTag(data) }}</span>
                                                    <button class="delete_btn" @click="removeHash(index)"></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="w_content w_self">
                                        <el-input type="text" :placeholder="$t('w_hash')" v-model="hashInput"
                                                  @input="setData('hashInput',hashInput)"
                                                  @keyup.enter.native="changeHash(2,hashInput)"/>
                                        <div class="plus_btn" for="addition" @click="changeHash(2,hashInput)"></div>
                                        <div class="d_hash_tag_wrap" v-for="(data,index) in hashtag"
                                             :key="`hash${index}`"
                                             v-if="data.type === 2">
                                            <div class="hash_wrap">
                                                <span class="hash_name ">{{ data.tag }}</span>
                                                <button class="delete_btn" @click="removeHash(index)"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="val_cap" v-if="validation.hasError('hashtag')">
                                        {{ validation.firstError('hashtag') }}
                                    </div>

                                </div>
                            </li>

                            <!--              아이템 설명-->
                            <li class="i_w_content inform">
                                <h3 class="sub_title no_caution_img">{{ $t('i_inform') }}</h3>
                                <div class="portfolio_edit_textarea">
<!--                                    <el-input type="textarea" id="content" v-model="content"
                                              @input="parentChange('content', content)"
                                              :placeholder="$t('input_inform')"></el-input>-->

                                    <quill-editor
                                        class="h800"
                                        ref="contentEditor"
                                        v-model="content"
                                        :options="editorOption"
                                    />
                                    <div class="val_cap" v-if="validation.hasError('content')">
                                        {{ validation.firstError('content') }}
                                    </div>
                                </div>
                            </li>

                        </ul>

                        <!--            일반 판매상태-->
                        <h2 class="sell_status">{{ $t('sell_status') }}</h2>
                        <ul class="i_write_content_wrap">

                            <!--              상태-->
                            <li class="i_w_content status_wrap">
                                <h3 class="sub_title">{{ $t('status') }}</h3>
                                <div class="w_content_box" v-if="checkCurator()">
                                    <el-radio-group class="el_radio_box" v-model="status"
                                                    @change="changeStatus" :disabled="itemData.ia_auction === 2">
                                        <el-radio class="el_radio_btn" :label="1">{{ $t('auction') }}</el-radio>
                                        <el-radio class="el_radio_btn" :label="3"
                                                  v-if="this.itemData.i_type !== 'real'">
                                            {{ $t('licenseSell') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="4">{{ $t('fix_selling') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="9">{{ $t('no_sell') }}</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="w_content_box" v-else>
                                    <el-radio-group class="el_radio_box" v-model="status"
                                                    @change="changeStatus" :disabled="itemData.ia_auction === 2">
                                        <el-radio class="el_radio_btn" :label="2">{{ $t('auction') }}</el-radio>

                                        <el-radio class="el_radio_btn" :label="5">{{ $t('fix_selling') }}
                                        </el-radio>
                                        <el-radio class="el_radio_btn" :label="9">{{ $t('no_sell') }}</el-radio>
                                    </el-radio-group>
                                </div>
                            </li>

                            <!--              최소입찰가-->
                            <li class="i_w_content mini_bid_wrap" v-if="status === 1 || status===2">
                                <h3 class="sub_title">{{ $t('mini_bid') }}</h3>
                                <div class="w_content_box">
                                    <el-radio-group class="el_radio_box" v-model="minimumSetting"
                                                    :disabled="itemData.ia_auction === 2"
                                                    @change="parentChange('minimumSetting', minimumSetting)">
                                        <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                                        <el-radio class="el_radio_btn" :label="0">{{ $t('no_setting') }}
                                        </el-radio>
                                    </el-radio-group>
                                    <div class="mg_input">
                                        <div class="content_name">{{ $t('mini_bid') }}</div>
                                        <el-input type="tel" v-model="minimum" placeholder="0"
                                                  class="el_input_num"
                                                  @input="returnNumber('minimum',minimum)"
                                                  :disabled="minimumSetting === 0 || itemData.ia_auction === 2"/>
                                    </div>
                                    <div class="val_cap" v-if="validation.hasError('minimum')&& minimumSetting === 1">
                                        {{ validation.firstError('minimum') }}
                                    </div>
                                    <div class="val_cap" v-if="!validation.hasError('minimum') && maxPriceErrorMsg">
                                        {{ maxPriceErrorMsg }}
                                    </div>
                                </div>
                            </li>

                            <!--              즉시구매가-->
                            <li class="i_w_content buy_now_wrap" v-if="status === 1 || status===2">
                                <h3 class="sub_title">{{ $t('i_buy') }}1111111111111111111111</h3>
                                <div class="w_content_box">
                                    <el-radio-group class="el_radio_box" v-model="immediatelySetting"
                                                    :disabled="itemData.ia_auction === 2"
                                                    @change="parentChange('immediatelySetting', immediatelySetting)">
                                        <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                                        <el-radio class="el_radio_btn" :label="0">{{ $t('no_setting') }}
                                        </el-radio>
                                    </el-radio-group>
                                    <div class="mg_input">
                                        <div class="content_name">{{ $t('i_buy') }}</div>l
                                        <el-input type="tel" v-model="immediately" placeholder="0"
                                                  class="el_input_num"
                                                  @input="returnNumber('immediately',immediately)"
                                                  :disabled="immediatelySetting === 0 || itemData.ia_auction === 2"/>
                                    </div>
                                    <div class="val_cap"
                                         v-if="validation.hasError('immediately') && immediatelySetting === 1">
                                        {{ validation.firstError('immediately') !==  $t('auctionErrorMsg') ?  validation.firstError('immediately') : '' }}
                                    </div>
                                  <div class="val_cap" v-if="returnErrorMinSetting()">
                                    {{ validation.firstError('immediately') ===  $t('auctionErrorMsg') ? $t('auctionErrorMsg') : '' }}
                                  </div>
                                    <div class="val_cap" v-if="!validation.hasError('immediately') && immediately === maxPriceErrorMsg">
                                        {{ maxPriceErrorMsg }}
                                    </div>
                                </div>
                            </li>

                            <!--              경매시작일-->
                            <li class="i_w_content detail_info_wrap time_wrap" v-if="status === 1 || status===2">
                                <h3 class="sub_title ">{{ $t('start_bid') }}</h3>
                                <div class="w_content_box">
                                    <el-radio-group class="el_radio_box" v-model="startDateStatus"
                                                    :disabled="itemData.ia_auction === 2"
                                                    @change="parentChange('startDateStatus', startDateStatus)">
                                        <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                                        <el-radio class="el_radio_btn" :label="0">{{ $t('start') }}
                                        </el-radio>
                                    </el-radio-group>
                                    <ul class="sub_content detail_wrap">
                                        <li>
                                            <div class="content_name ">
                                                {{ $t('start_bid') }}
                                            </div>
                                            <el-date-picker
                                                    :disabled="itemData.ia_auction === 2 || startDateStatus===0"
                                                    class="range_time"
                                                    v-model="startDate"
                                                    :picker-options="startPickerOptions"
                                                    @change="dateChange('startDate', startDate)"
                                                    format="yyyy-MM-dd HH:mm"
                                                    type="datetime"
                                                    popper-class="not_now"
                                                    placeholder="YYYY-MM-DD HH:MM">
                                            </el-date-picker>
                                            <div class="val_cap" v-if="validation.hasError('startDate')">
                                                {{ validation.firstError('startDate') }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <!--              경매종료일-->
                            <li class="i_w_content detail_info_wrap time_wrap" v-if="status === 1 || status===2">
                                <h3 class="sub_title ">{{ $t('end_bid') }}</h3>
                                <div class="w_content_box">
                                    <ul class="sub_content detail_wrap mt0 pt0">
                                        <li>
                                            <div class="content_name ">
                                                {{ $t('end_bid') }}
                                            </div>
                                            <el-date-picker
                                                    :disabled="itemData.ia_auction === 2"
                                                    class="range_time"
                                                    v-model="endData"
                                                    @change="dateChange('endData', endData)"
                                                    :picker-options="endPickerOptions"
                                                    type="datetime"
                                                    format="yyyy-MM-dd HH:mm"
                                                    popper-class="not_now"
                                                    placeholder="YYYY-MM-DD HH:MM">
                                            </el-date-picker>
                                            <div class="val_cap" v-if="validation.hasError('endData')">
                                                {{ validation.firstError('endData') }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            <!--              라이센스 판매-->
                            <li class="i_w_content l_selling_wrap" v-if="status === 3">
                                <h3 class="sub_title">{{ $t('selling_price') }}</h3>
                                <div class="w_content_box">
                                    <div class="sub_content" v-if="itemData.im_type === 'photo'">
                                        <div class="sub_li" v-if="smallType">
                                            <div class="sub_li_title mt7">
                                                <span>Small</span>
                                                <p>{{ Math.floor(smallSize[0]) }}*{{ Math.floor(smallSize[1]) }} px </p>
                                            </div>
                                            <div class="sub_li_input">
                                                <el-input type="tel" v-model="smallPrice" placeholder="0"
                                                          @input="returnNumber('smallPrice',smallPrice)"
                                                          class="el_input_num"></el-input>
                                            </div>
                                            <div class="val_cap" v-if="validation.hasError('smallPrice')">
                                                {{ validation.firstError('smallPrice') }}
                                            </div>
                                            <div class="val_cap"
                                                 v-if="!validation.hasError('smallPrice') && maxPriceErrorMsg">
                                                {{ maxPriceErrorMsg }}
                                            </div>
                                        </div>

                                        <div class="sub_li" v-if="mediumType">
                                            <div class="sub_li_title mt7">
                                                <span>Medium</span>
                                                <p>{{ Math.floor(mediumSize[0]) }}*{{ Math.floor(mediumSize[1]) }}
                                                    px</p>
                                            </div>
                                            <div class="sub_li_input">
                                                <el-input type="tel" v-model="mediumPrice" placeholder="0"
                                                          @input="returnNumber('mediumPrice',mediumPrice)"
                                                          class="el_input_num"></el-input>
                                            </div>
                                            <div class="val_cap" v-if="validation.hasError('mediumPrice')">
                                                {{ validation.firstError('mediumPrice') }}
                                            </div>
                                            <div class="val_cap"
                                                 v-if="!validation.hasError('mediumPrice') && maxPriceErrorMsg">
                                                {{ maxPriceErrorMsg }}
                                            </div>
                                        </div>

                                        <div class="sub_li" v-if="largeType">
                                            <div class="sub_li_title  mt7">
                                                <span>Large</span>
                                                <p>{{ Math.floor(largeSize[0]) }}*{{ Math.floor(largeSize[1]) }} px </p>
                                            </div>
                                            <div class="sub_li_input">
                                                <el-input type="tel" v-model="largePrice" placeholder="0"
                                                          @input="returnNumber('largePrice',largePrice)"
                                                          class="el_input_num"></el-input>
                                            </div>
                                            <div class="val_cap" v-if="validation.hasError('largePrice')">
                                                {{ validation.firstError('largePrice') }}
                                            </div>
                                            <div class="val_cap"
                                                 v-if="!validation.hasError('largePrice') && maxPriceErrorMsg">
                                                {{ maxPriceErrorMsg }}
                                            </div>
                                        </div>

                                        <div class="sub_li">
                                            <div class="sub_li_title mt7">
                                                <span>Original</span>
                                                <p>{{ itemData.imo_size }} px </p>
                                            </div>
                                            <div class="sub_li_input">
                                                <el-input type="tel" v-model="originalPrice" placeholder="0"
                                                          @input="returnNumber('originalPrice',originalPrice)"
                                                          class="el_input_num"></el-input>
                                            </div>
                                        </div>
                                        <div class="val_cap" v-if="validation.hasError('originalPrice')">
                                            {{ validation.firstError('originalPrice') }}
                                        </div>
                                        <div class="val_cap"
                                             v-if="!validation.hasError('originalPrice') && maxPriceErrorMsg">
                                            {{ maxPriceErrorMsg }}
                                        </div>
                                    </div>
                                    <div class="mg_input" v-else>
                                        <div class="content_name">{{ $t('license_sell_amount') }}</div>
                                        <el-input type="tel" v-model="originalPrice" placeholder="0"
                                                  @input="returnNumber('originalPrice',originalPrice)"
                                                  class="el_input_num"/>
                                        <div class="val_cap" v-if="validation.hasError('originalPrice')">
                                            {{ validation.firstError('originalPrice') }}
                                        </div>
                                        <div class="val_cap"
                                             v-if="!validation.hasError('originalPrice') && maxPriceErrorMsg">
                                            {{ maxPriceErrorMsg }}
                                        </div>
                                    </div>

                                </div>
                            </li>

                            <li class="i_w_content license_caution_wrap" v-if="status === 3">
                                <h3 class="sub_title">{{ $t('caution_license') }}</h3>
                                <div class="w_content_box">
                                    <div class="textarea_wrap">
                                        <div class="content_name">{{ $t('cautions') }}</div>
                                        <el-input type="textarea" v-model="caution"
                                                  :placeholder="$t('caution_license_input')"
                                        ></el-input>
                                    </div>
                                </div>
                            </li>

                            <li class="i_w_content l_selling_wrap" v-if="status === 4 ||status===5">
<!--                                <h3 class="sub_title">{{ $t('selling_price') }}</h3>-->
                                <div class="w_content_box">
                                    <div class="mg_input">
                                        <div class="content_name">{{ $t('fix_amount') }}</div>
                                        <el-input type="tel" v-model="i_price" placeholder="0"
                                                  @input="returnNumber('i_price',i_price)"
                                                  class="el_input_num"/>
                                    </div>
                                    <div class="val_cap" v-if="validation.hasError('i_price')">
                                        {{ validation.firstError('i_price') }}
                                    </div>
                                    <div class="val_cap" v-if="!validation.hasError('i_price') && maxPriceErrorMsg">
                                        {{ maxPriceErrorMsg }}
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="submit_main_wrap">
            <div class="search_item_wrap terms_content_btn">
                <div class="search_item">
                    <el-checkbox v-model="termsCheck" v-if="status !== 9" @click="changeTerms"
                                 :disabled="itemData.ia_auction === 2">
                        <span class="item_text_span bold" @click="openModal('terms-modal','auctionSell')"
                              v-if="status === 1 || status === 2">{{ $t('bid_terms') }}</span>

                        <span class="item_text_span bold" @click="openModal('terms-modal','productSell')"
                              v-else-if="status === 4 || status === 5">{{ $t('product_sell_terms') }}</span>

                        <span class="item_text_span bold" @click="openModal('terms-modal','licenseSell')"
                              v-else>{{ $t('license_terms') }}</span>

<!--                        <span class="item_text_span">{{ $t('agree_terms2') }}</span>-->
                      <span v-html="$t('agree_terms2')"></span>

                    </el-checkbox>
                </div>
            </div>
            <div class="submit_wrap item_create_btn">
                <div class="submit_btn">
                    <button class="enroll_submit_btn" @click="save" :disabled="checkUpdate()">{{ $t('save') }}</button>
                </div>
            </div>
            <div class="caption_check">
                <div class="mt10" v-if="termsMsg"><p>{{ $t('caption_check') }}</p></div>
                <div class="mt10" v-if="errorMsg"><p>{{ $t('caption_status_terms') }}</p></div>
            </div>
        </div>

        <!--        <terms-modal :termsType="termsType"></terms-modal>-->
    </div>

</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import quillEditorMixins from "@/mixins/quillEditorMixins";
import {mapState} from "vuex";
import itemValidator from "@/mixins/validators/itemValidator";
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import priceMixins from "@/mixins/priceMixins";
import imageOption from "@/mixins/imageOption";
import TermsModal from "@/components/modal/TermsModal";
import fetch from "node-fetch";
import axios from "axios";
import fileMixins from "@/mixins/fileMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";


export default {
    name: "ItemWriteStatusLayout",
    mixins: [quillEditorMixins, itemValidator, elFileMixins, alertMixins, imageOption, fileMixins, priceMixins, imageResizeMixins, awsS3FileDownload],
    components: {
        TermsModal,
        Swiper,
        SwiperSlide
    },
    inject: ['itemWriteSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemCategorys: {},
            detailCategorys: {},
            hashData: '',
            hashInput: '',
            tabChange: true,
            termsMsg: false,
            termsCheck: false,
            errorMsg: false,
            termsType: '',
            priceType: false,
            fileToken: '',
            startPickerOptions: {
                disabledDate: this.returnStartDate,
            },
            endPickerOptions: {
                disabledDate: this.returnEndDate,
            },
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if (this.status === 1) {
            this.termsType = 'auctionSell';
        } else {
            this.termsType = 'licenseSell';
        }
        if (!util.isEmpty(this.itemData)) {
            this.initSetData();
            console.log(this.checkUpdate());
        }
    },
    mounted() {
        this.itemCategorys = this.itemCategoryList;
        EventBus.$on('ItemWriteStatusLayoutValidator', this.checkValidator);
        window.scrollTo(0, 0)
    },
    beforeDestroy() {
        EventBus.$off('ItemWriteStatusLayoutValidator');

    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initData() {
            this.minimumSetting = 0;
            this.parentChange('minimumSetting', this.minimumSetting);
            this.minimum = '';
            this.parentChange('minimum', this.minimum);
            this.immediatelySetting = 0;
            this.parentChange('immediatelySetting', this.immediatelySetting);
            this.immediately = '';
            this.parentChange('immediately', this.immediately);
            this.startDate = '';
            this.parentChange('startDate', this.startDate);
            this.endData = '';
            this.parentChange('endData', this.endData);
            this.smallPrice = '';
            this.parentChange('smallPrice', this.smallPrice);
            this.mediumPrice = '';
            this.parentChange('mediumPrice', this.mediumPrice);
            this.largePrice = '';
            this.parentChange('largePrice', this.largePrice);
            this.extraLargePrice = '';
            this.parentChange('extraLargePrice', this.extraLargePrice);
            this.originalPrice = '';
            this.parentChange('originalPrice', this.originalPrice);
            this.i_price = '';
            this.parentChange('i_price', this.i_price);
            this.caution = '';
            this.parentChange('caution', this.caution);
            this.termsCheck = false;
        },
        initSetData() {
            this.i_idx = this.itemData.i_idx;
            this.parentChange('i_idx', this.i_idx);
            this.title = this.itemData.i_name;
            this.parentChange('title', this.title);
            this.content = this.itemData.i_memo;
            this.content = util.htmlDecode(this.content);
            this.parentChange('content', this.content);
            this.type = this.itemData.i_type;
            this.parentChange('type', this.type);
            this.category = this.itemData.ic_idx;
            this.parentChange('category', this.category);
            let categoryIndex = this.itemCategoryList.findIndex(category => category.node_id === this.category);
            this.detailCategorys = this.itemCategoryList[categoryIndex];
            this.edition = this.itemData.im_edition;
            this.parentChange('edition', this.edition);
            this.count = this.itemData.im_count;
            this.parentChange('count', this.count);
            if (!util.isEmpty(this.itemData.im_total)) {
                this.total = this.itemData.im_total;
                this.parentChange('total', this.total);
            }
            this.i_status = this.itemData.i_status;
            this.parentChange('i_status', this.i_status);
            this.originator = this.itemData.im_originator;
            this.parentChange('originator', this.originator);
            this.im_type = this.itemData.im_type;
            this.parentChange('im_type', this.im_type);
            this.enroll = this.itemData.im_enroll;
            this.parentChange('enroll', this.enroll);
            this.adult = this.itemData.im_adult_chk;
            this.parentChange('adult', this.adult);
            this.size = this.itemData.imo_size;
            this.parentChange('size', this.size);
            if (this.itemData.im_type === 'photo') {
                let afterSize = this.size.split('*');
                this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
            }

            this.status = this.itemData.im_status;
            this.parentChange('status', this.status);
            //경매 데이터
            this.minimumSetting = this.itemData.ia_is_minimum;
            this.parentChange('minimumSetting', this.minimumSetting);
            this.minimum = this.itemData.ia_minimum;
            this.returnNumber('minimum', this.minimum);
            this.immediatelySetting = this.itemData.ia_is_immediately;
            this.parentChange('immediatelySetting', this.immediatelySetting);
            this.immediately = this.itemData.ia_immediately;
            this.returnNumber('immediately', this.immediately);
            this.startDate = this.itemData.ia_start_date;
            this.parentChange('startDate', this.startDate);
            this.endData = this.itemData.ia_end_date;
            this.parentChange('endData', this.endData);
            //라이센스 데이터
            this.caution = this.itemData.il_caution;
            this.parentChange('caution', this.caution);
            this.extraLargePrice = this.itemData.il_extra_large_price;
            this.returnNumber('extraLargePrice', this.extraLargePrice);
            this.largePrice = this.itemData.il_large_price;
            this.returnNumber('largePrice', this.largePrice);
            this.mediumPrice = this.itemData.il_medium_price;
            this.returnNumber('mediumPrice', this.mediumPrice);
            this.originalPrice = this.itemData.il_original_price;
            this.returnNumber('originalPrice', this.originalPrice);
            this.smallPrice = this.itemData.il_small_price;
            this.returnNumber('smallPrice', this.smallPrice);
            //지정가 데이터
            this.i_price = this.itemData.i_price;
            this.returnNumber('i_price', this.i_price);


            this.itemData.Hash.forEach(data => {
                let hash = {type: data.ih_type, tag: data.ih_hashtag}
                if (data.ih_type === 1) {
                    hash.tag = data.ic_idx;
                }
                this.hashtag.push(hash);
            })
            this.parentChange('hashtag', this.hashtag);

            if (!util.isEmpty(this.itemData.ItemFile.Detail)) {
                if (!util.isEmpty(this.itemData.ItemFile.Detail[0].a_idx)) {
                    this.detailThumnail = this.itemData.ItemFile.Detail;
                }
                this.parentChange('detailThumnail', this.detailThumnail);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Guaranty)) {
                if (!util.isEmpty(this.itemData.ItemFile.Guaranty[0].a_idx)) {
                    this.guarantee = this.itemData.ItemFile.Guaranty;
                }
                this.parentChange('guarantee', this.guarantee);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Item)) {
                if (!util.isEmpty(this.itemData.ItemFile.Item[0].a_idx)) {
                    this.thumnail = this.itemData.ItemFile.Item;
                }
                this.parentChange('thumnail', this.thumnail);
            }
            if (!util.isEmpty(this.itemData.ItemFile.NFT)) {
                if (!util.isEmpty(this.itemData.ItemFile.NFT[0].a_idx)) {
                    this.original = this.itemData.ItemFile.NFT;
                }
                this.parentChange('original', this.original);
            }
            if (this.itemData.ia_auction === 2) {
                this.termsCheck = true;
            }
        },

        movePage(url) {
            this.$router.push(url);
        },
        parentChange(type, value) {
            this[type] = value
            this.itemWriteSetData(type, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        returnStartDate(time) {
            if (!util.isEmpty(this.endData)) {
                return time.getTime() > this.$moment(this.endData) || time.getTime() < this.$moment();
            } else {
                return time.getTime() < this.$moment().add(-1, 'day');
            }
        },
        returnEndDate(time) {
            if (!util.isEmpty(this.startDate)) {
                return time.getTime() <= this.$moment(this.startDate).add(-1, 'day');
            } else {
                return time.getTime() < this.$moment().add(-1, 'day');
            }

        },
        changeHash(key, value) {
            if (util.isEmpty(value)) {
                return false;
            }
            if (this.hashtag.length > 50) {
                this.errorAlert(this.$t('l_hash'));
                return;
            }
            let hash = {type: key, tag: value};
            if (key === 1) {
                this.hashData = value;
            }
            let hashIndex = this.hashtag.findIndex(category => category.tag === value);
            if (hashIndex > -1) {
                return
            }
            this.hashtag.push(hash);
            this.parentChange('hashtag', this.hashtag);
            this.hashInput = "";
        },
        removeHash(index) {
            this.hashtag.splice(index, 1);
        },
        setCategory(value) {
            this.parentChange('category', value);
            this.hashtag = [];
            this.hashData = '';
            this.hashInput = '';
            //this.detailCategorys = this.itemCategorys[value];
            let categoryIndex = this.itemCategorys.findIndex(category => category.node_id === value);
            if (!util.isEmpty(this.itemCategorys[categoryIndex].child)) {
                this.detailCategorys = this.itemCategorys[categoryIndex];
            } else {
                this.detailCategorys = [];
            }
        },
        returnHashTag(data) {
            let detailTag = Number(data.tag);
            let hashIndex = this.detailCategorys.child.findIndex(category => category.node_id === detailTag);
            if (hashIndex > -1) {
                return this.detailCategorys.child[hashIndex].NAME;
            }
        },

        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        changeFileType(type) {
            this.fileType = type;
        },
        checkValidator(dataArr) {
            this.$validate(dataArr).then(success => {
                if (!success) {
                    this.errorMsg = true;
                } else {
                    this.errorMsg = false;
                }
            })
        },

        removeList(type, index) {
            let files = this[type].splice(index, 1);
            if (!util.isEmpty(files[0].a_idx)) {
                this.deleteFiles.push(files[0])
                this.parentChange(`deleteFiles`, this.deleteFiles)
            }
            this.parentChange(`${type}`, this[type])
            this.$forceUpdate();
        },
        photoListChange(file, fileList) {
            if (this.detailThumnail.length > 9) {
                this.errorAlert(this.$t('l_img1'))
            } else {
                this.handleFileChange(file, fileList);
                this.$forceUpdate();
            }
        },
        returnCategoryName(data) {
            if (data === 2) {
                return `${this.$t('art')}`
            } else if (data === 3) {
                return `${this.$t('picture')}`
            } else if (data === 4) {
                return `${this.$t('video')}`
            } else if (data === 5) {
                return `${this.$t('music')}`
            } else if (data === 9) {
                return `${this.$t('collections')}`
            } else if(data === 178){
                return `${this.$t('prompt')}`
            }
        },
        returnItemType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        returnProfile(data) {
            if (!util.isEmpty(data.profile.originator)) {
                return `${data.profile.originator[0].org_url}?${this.setImageOptions(25, 25, 'png')}`
            }
        },
        returnItemImg(data) {
            if (!util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(730, null, 'png')}`
            }
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },
        changeTerms() {
            this.termsCheck = !this.termsCheck;
        },
        save() {

            if (this.status !== 9 && !this.termsCheck) {
                this.termsMsg = true;
                return
            }
            this.termsMsg = false;
            EventBus.$emit('updateItem');
        },

        changeStatus() {
            this.initData();
            if (this.status === 1 || this.status === 2) {
                this.i_status = 'auction'
            } else if (this.status === 3) {
                this.i_status = 'license'
            } else if (this.status === 4 || this.status === 5) {
                this.i_status = 'pricetag'
            } else {
                this.i_status = 'nosale'
            }
            this.parentChange('i_status', this.i_status);
            this.parentChange('status', this.status);
        },

        checkFile(data) {
            return !util.isEmpty(data.a_idx);
        },
        deleteItem() {
            EventBus.$emit('deleteItem');
        },
        checkOriginal() {
            return !util.isEmpty(this.itemData.originator_nick)
        },

        checkCurator() {
            return util.isEmpty(this.itemData.ibc_curator)
        },
        dateChange(key, value) {
            let date = this.$moment(value).format('YYYY-MM-DD HH:mm')
            if (util.isEmpty(value)) {
                date = '';
            }

            this.parentChange(key, date)
        },
        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);

            this.parentChange(type, number)
            this[type] = util.Number.numFormat(number);
        },
        awsS3Download(type, name, key, nft) {
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        },
        checkUpdate() {
            if (util.isEmpty(this.itemData)) {
                return true;
            }
            if (util.isEmpty(this.UserInfo)) {
                return true;
            }
            if (!util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
                return false;
            }
            if (util.isEmpty(this.itemData.ibc_curator) && this.itemData.provider === this.UserInfo.mb_no) {
                return false;
            }
            return true;
        },
      returnErrorMinSetting(){
        if(this.minimumSetting <= this.minimum){
          return true;
        }else{
          return false;
        }
      }
    },
    watch: {
        'content': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('content', this.content)
            },
        },
        'caution': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('caution', this.caution)
            },
        },
    },
}
</script>

<style scoped>

</style>
